import React, {Component} from 'react';
import API, {DustProduct, getErrorMessage, submitPostForm} from '../api/API';
import {brand} from '../Brand';
import Back from '../component/Back';
import Logo from '../component/Logo';
import TermsCheckbox from '../component/TermsCheckbox';
import Footer from '../Footer';
import './ShopPage.css';

class ShopPage extends Component<{}, {}> {

	state = {
		playerName: '',
		email: '',
		dustPrices: null as DustProduct[] | null,
		selectedAmount: null as DustProduct | null,
		termsAccepted: false
	}

	componentDidMount() {
		API.getDustPrices(response => {
			if (response.isError()) {
				alert("Произошла ошибка, обнови страницу");
			} else {
				this.setState({dustPrices: response.response!, selectedAmount: response.response![0]})
			}
		})
	}

	render() {
		let comboBox: JSX.Element | null;

		if (this.state.dustPrices !== null) {
			const select = (v: number) => this.setState({selectedAmount: this.state.dustPrices?.find(p => p.amount === v)!});

			comboBox = <select value={this.state.selectedAmount!.amount} onChange={e => select(+e.target.value)}>
				{this.state.dustPrices.map(p => <option key={p.amount} value={p.amount}>{p.amountWithoutBonus} пыли{p.bonus > 0 ? ' (+ бонус)' : ''} ({p.price} ₽)</option>)}
			</select>
		} else {
			comboBox = <select/>;
		}

		return <div className="shop">
			<div className="center">
				<Logo/>

				<div>
					<Back/>

					<h1>Покупка пыли</h1>

					<input placeholder="Ник" value={this.state.playerName} onChange={e => this.setState({playerName: e.target.value})}/>

					<input placeholder="Электронная почта" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>

					{comboBox}

					<TermsCheckbox accepted={this.state.termsAccepted} onChange={v => this.setState({termsAccepted: v})}/>

					<button className="buy" disabled={!this.canBuy()} onClick={e => this.onClick(e)}>
						Купить{this.state.selectedAmount === null ? '' : ' (' + this.state.selectedAmount.price + ' ₽)'}
					</button>
				</div>
			</div>

			<Footer/>
		</div>;
	}

	private canBuy() {
		return this.state.dustPrices !== null &&
			this.state.playerName.length >= 3 &&
			this.state.email.length >= 3 &&
			this.state.termsAccepted;
	}

	private onClick(e: React.MouseEvent<HTMLButtonElement>) {
		const button = (e.target as any);

		button.disabled = true;

		API.purchaseDust(
			this.state.playerName,
			this.state.email,
			this.state.selectedAmount!.amount,
			brand.shop,
			response => {
				button.disabled = false;

				if (response.error) {
					alert(getErrorMessage(response.error));
				} else {
					submitPostForm(response.response!.redirectUrl);
				}
			}
		);
	}

}

export default ShopPage;
