import {createBrowserHistory} from 'history';
import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import MainPage from './page/MainPage';
import PurchaseDustFromClientPage from './page/PurchaseDustFromClientPage';
import ShopPage from './page/ShopPage';
import StaticPage from './page/StaticPage';

export const browserHistory = createBrowserHistory();

const App = () => <Router history={browserHistory}>
	<Switch>
		<Route path='/static/:pageName' render={(props) => <StaticPage pageName={props.match.params.pageName}/>}/>
		<Route path='/purchaseDust' component={ShopPage}/>
		<Route path='/purchaseDustFromClient' component={PurchaseDustFromClientPage}/>
		<Route component={MainPage}/>
	</Switch>
</Router>;

export default App;