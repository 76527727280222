import React, {Component} from 'react';
import API, {DustProduct, getErrorMessage, submitPostForm} from '../api/API';
import {browserHistory} from '../App';
import {brand} from '../Brand';
import Logo from '../component/Logo';
import TermsCheckbox from '../component/TermsCheckbox';
import Footer from '../Footer';
import './ShopPage.css';

class PurchaseDustFromClientPage extends Component {

	private playerName = '';
	private amount = 0;

	state = {
		email: '',
		product: null as DustProduct | null,
		termsAccepted: false
	}

	constructor(props: {}, context: any) {
		super(props, context);

		this.parseQueryParameters();
	}

	private parseQueryParameters() {
		const search = window.location.search;

		if (!search.startsWith('?')) {
			browserHistory.push('/');
			return;
		}

		for (const pair of search.substring(1).split('&')) {
			let kv = pair.split('=');

			if (kv[0] === 'playerName') {
				this.playerName = kv[1];
			} else if (kv[0] === 'amount') {
				this.amount = +kv[1];
			}
		}

		if (this.playerName === '' || this.amount < 1) {
			browserHistory.push('/');
		}
	}

	componentDidMount() {
		API.getDustPrice(this.amount, response => {
			if (!response.isError()) {
				this.setState({product: response.response!});
			}
		});
	}

	render() {
		const {product} = this.state;
		const amount = product === null ? '' : product.amountWithoutBonus + ' пыли' + (product.bonus > 0 ? ' (+ бонус)' : '');
		const price = product === null ? '' : ' (' + product.price + ' ₽)';

		return <div className="shop">
			<div className="center">
				<Logo/>

				<div>
					<h1>Покупка пыли</h1>

					<input value={this.playerName} disabled={true}/>

					<input value={amount} disabled={true}/>

					<input placeholder="Электронная почта" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>

					<TermsCheckbox accepted={this.state.termsAccepted} onChange={v => this.setState({termsAccepted: v})}/>

					<button className="buy" disabled={!this.canBuy()} onClick={e => this.onClick(e)}>
						Купить{price}
					</button>
				</div>
			</div>

			<Footer/>
		</div>;
	}

	private canBuy() {
		return this.state.email.length >= 3 && this.state.termsAccepted;
	}

	private onClick(e: React.MouseEvent<HTMLButtonElement>) {
		const button = (e.target as any);

		button.disabled = true;

		API.purchaseDust(
			this.playerName,
			this.state.email,
			this.amount,
			brand.shop,
			response => {
				button.disabled = false;

				if (response.error) {
					alert(getErrorMessage(response.error));
				} else {
					submitPostForm(response.response!.redirectUrl);
				}
			}
		);
	}

}

export default PurchaseDustFromClientPage;
