import React, {Component} from 'react';
import API from '../api/API';
import Back from '../component/Back';
import Logo from '../component/Logo';
import Footer from '../Footer';
import './StaticPage.css';

const pageCache: {[key: string]: string} = {};

class StaticPage extends Component<{pageName: string}> {

	state = {
		loaded: false,
		contents: ''
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps: Readonly<{ pageName: string }>, prevState: Readonly<{}>, snapshot?: any) {
		if (prevProps.pageName !== this.props.pageName) {
			this.reload();
		}
	}

	private reload() {
		const cached = pageCache[this.props.pageName];

		if (cached !== undefined) {
			this.setState({loaded: true, contents: cached});

			return;
		}

		this.setState({loaded: false});

		API.getStaticPage(this.props.pageName, response => {
			let html: string;

			if (response.isError()) {
				if (response.error!.code === 12) {
					html = "<div>Страница не найдена</div>";
				} else {
					html = "<div>Произошла ошибка при загрузке страницы, обнови страницу</div>";
				}
			} else {
				html = response.response!.pageHtml;
			}

			this.setState({loaded: true, contents: html});
		});
	}

	render() {
		let contents: string;

		if (this.state.loaded) {
			contents = this.state.contents;
		} else {
			contents = '<p>Идёт загрузка...</p>';
		}

		return <div className="staticPage">
			<Logo/>

			<div>
				<Back/>

				<div className="contents" dangerouslySetInnerHTML={{__html: contents}}/>
			</div>

			<Footer/>
		</div>;
	}

}

export default StaticPage;
