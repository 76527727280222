import {Link} from 'react-router-dom';
import {brand} from './Brand';
import './Footer.css';

const email = 'help@' + window.location.host;

const Footer = () => <footer>
	<div>Copyright &copy; {brand.name} 2021. Все права защищены.</div>
	<div>Для получения дополнительной информации и помощи обратитесь по адресу <a href={'mailto:' + email}>{email}</a></div>
	<div>ИП Калинин Илья Сергеевич (ИНН 272210678597 ОРНИП 321272400006253)</div>
	<div>
		<Link to={'/static/offerAgreement' + brand.staticPageSuffix}>Договор-оферта</Link>
		{' | '}
		<Link to={'/static/privacy' + brand.staticPageSuffix}>Политика обработки персональных данных</Link>
	</div>
	<div>
		<Link to={'/static/paymentProcess' + brand.staticPageSuffix}>Порядок проведения оплаты и безопасность операций</Link>
	</div>
	<div className="icons">
		<img src="/six-plus.png" height="40px" alt="6+"/>
	</div>
</footer>;

export default Footer;
