import React from 'react';
import {Link} from 'react-router-dom';
import {brand} from '../Brand';
import './TermsCheckbox.css';

const TermsCheckbox = (props: { accepted: boolean, onChange: (accepted: boolean) => void }) => <div className="terms-checkbox">
	<input
		type="checkbox"
		id="terms-checkbox"
		className="terms-checkbox"
		defaultChecked={props.accepted}
		onChange={e => props.onChange(e.target.checked)}
	/>

	<label htmlFor="terms-checkbox">
		Я согласен с <Link to={'/static/offerAgreement' + brand.staticPageSuffix} target="_blank">договором</Link>
		{' и '}
		<Link to={'/static/privacy' + brand.staticPageSuffix} target="_blank">политикой конфиденциальности</Link>.
	</label>
</div>;

export default TermsCheckbox;
