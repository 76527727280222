import axios, {AxiosPromise} from 'axios';
import querystring from 'querystring';

export interface APIError {

	code: number;
	message: string;
	data: {[key: string]: any};

}

class APIResponse<T> {

	error?: APIError;
	response?: T;

	constructor(error?: APIError, response?: T) {
		this.error = error;
		this.response = response;
	}

	isError() {
		return this.error !== undefined;
	}

}

const dev = window.location.host.startsWith('localhost');

const {protocol, host} = dev ?
	{protocol: 'http', host: '127.0.0.1'} :
	{protocol: 'https', host: window.location.host};

class CallbackError extends Error {

	cause: Error;

	constructor(cause: Error) {
		super();
		this.cause = cause;
	}

}

function apiCall(isPost: boolean, method: string, params: {[key: string]: any}, callback: APICallback<any>) {
	let promise: AxiosPromise;

	const paramString = querystring.stringify(params);

	if (isPost) {
		promise = axios.post(protocol + '://' + host + '/' + method, paramString === '' ? undefined : paramString, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
	} else {
		promise = axios.get(protocol + '://' + host + '/' + method + (paramString === '' ? '' : '?' + paramString), {withCredentials: true})
	}

	promise.then(response => {
		try {
			callback(new APIResponse(undefined, response.data));
		} catch (e) {
			throw new CallbackError(e)
		}
	}).catch(e => {
		if (e instanceof CallbackError) {
			throw e.cause;
		}
		
		const error = e.response === undefined ?
				{code: -1, message: 'Network error', data: {}} :
				{code: e.response.data.code, message: e.response.data.message, data: e.response.data};

		callback(new APIResponse(error, undefined));
	})
}

export type APICallback<T> = (response: APIResponse<T>) => void;

export type DustProduct = {amount: number, amountWithoutBonus: number, bonus: number, price: number};

class API {

	static getStaticPage(pageName: string, callback: APICallback<{pageHtml: string}>) {
		apiCall(false, 'api/site/getStaticPage', {pageName}, callback)
	}

	static getDustPrices(callback: APICallback<DustProduct[]>) {
		apiCall(false, 'api/site/getDustPrices', {}, callback)
	}

	static getDustPrice(amount: number, callback: APICallback<DustProduct>) {
		apiCall(false, 'api/site/getDustPrice', {amount}, callback)
	}

	static purchaseDust(playerName: string, email: string, amount: number, shop: string, callback: APICallback<{redirectUrl: string}>) {
		apiCall(true, 'api/site/purchaseDust', {playerName, email, amount, shop}, callback)
	}

}

export default API;

export function getErrorMessage(error: APIError) {
	const oldData = 'Данные устарели, обновите страницу и попробуйте ещё раз';

	const messagesByCode: {[key: number]: string} = {
		11: 'Ник содержит неверные символы, или слишком длинный, или слишком короткий',
		12: 'Такой промокод не существует',
		13: 'Этот промокод истёк и больше не может использоваться',
		14: 'Этот промокод был использован слишком много раз',
		15: oldData,
		16: oldData,
		17: oldData,
		18: oldData,
		19: 'Некорректный адрес электронной почты',
		22: 'Выбрано слишком много товаров, уменьшите список покупок',
		23: 'Не выбрано нормальных товаров для покупки (не покупайте разбан или размут, если вы не забанены и не замучены)'
	}

	return messagesByCode[error.code] || 'Произошла ошибка, обновите страницу и попробуйте ещё раз';
}

function getParams(url: string): {[key: string]: string} {
	const i = url.indexOf('?');

	if (i === -1) {
		throw Error('No URL parameters in ' + url);
	}

	const result: {[key: string]: string} = {};

	url.substring(i + 1).split('&').forEach(pair => {
		const j = pair.indexOf('=');

		if (j === -1) {
			throw Error('No value in key-value pair ' + pair);
		} else {
			const val = pair.substring(j + 1).replace(/\+/g, "%20");

			result[decodeURIComponent(pair.substring(0, j))] = decodeURIComponent(val);
		}
	});

	return result;
}

function createForm(url: string) {
	const params = getParams(url);

	const form = document.createElement("form");
	form.method = "POST";
	form.action = url.substring(0, url.indexOf('?'));

	Object.entries(params).forEach(e => {
		const input = document.createElement("input");
		input.name = e[0];
		input.value = e[1];
		form.appendChild(input);
	});

	return form;
}

export function submitPostForm(url: string): void {
	window.location.href = url;

	//const form = createForm(url);

	//document.body.appendChild(form);

	//form.submit();
}
