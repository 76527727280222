import {useState} from 'react';
import {Link} from 'react-router-dom';
import {brand} from '../Brand';
import Logo from '../component/Logo';
import Footer from '../Footer';
import './MainPage.css';

const DownloadButton = () => {
	const [dropDown, setDropDown] = useState(false);

	return <div className="downloadButton" onClick={() => setDropDown(!dropDown)}>Скачать<span>{dropDown ? '▲' : '▼'}</span>
		<div className="downloadDropDown" style={{display: dropDown ? 'block' : 'none'}}>
			<a href={brand.loaderUrlExe} target="_blank" rel="noreferrer">Windows <span>(.EXE)</span></a>
			<a href={brand.loaderUrlJar} target="_blank" rel="noreferrer">Windows, Linux, MacOS <span>(.JAR)</span></a>
		</div>
	</div>;
};

const MainPage = () => <div className="main">
	<div className="center">
		<Logo/>

		<div className="controls">
			<DownloadButton/>
			<div><Link to="/purchaseDust">Магазин</Link></div>
			<div><Link to={'/static/info' + brand.staticPageSuffix}>Информация</Link></div>
		</div>

		<div className="vk">
			<a href={brand.vkPageUrl} target="_blank" rel="noreferrer">
				<img src="/vk.png" alt="VK"/>
			</a>
		</div>

		<Footer/>
	</div>
</div>;

export default MainPage;
