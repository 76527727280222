export type Brand = {

	name: string;
	theme: string;
	shop: string;
	loaderUrlExe: string;
	loaderUrlJar: string;
	vkPageUrl: string;
	staticPageSuffix: string;
	logoPath: string;
	faviconPath: string;

}

const host = window.location.host;

let _brand: Brand;

if (host.indexOf('dcubes.ru') !== -1 || host.indexOf('divinecubes') !== -1) {
	_brand = {
		name: 'DCubes',
		theme: 'dcubes-theme',
		shop: 'dcubes',
		loaderUrlExe: 'https://dcubes.ru/loader/DCubes.exe',
		loaderUrlJar: 'https://dcubes.ru/loader/DCubes.jar',
		vkPageUrl: 'https://vk.com/divinecubes',
		staticPageSuffix: '-dcubes',
		logoPath: '/logo-dcubes.png',
		faviconPath: '/favicon-dcubes.png'
	}
} else {
	_brand = {
		name: 'ProstoLauncher',
		theme: 'prostolauncher-theme',
		shop: 'default',
		loaderUrlExe: 'https://prostolauncher.ru/loader/ProstoLauncher.exe',
		loaderUrlJar: 'https://prostolauncher.ru/loader/ProstoLauncher.jar',
		vkPageUrl: 'https://vk.com/prostolauncher',
		staticPageSuffix: '',
		logoPath: '/logo-prostolauncher.png',
		faviconPath: '/favicon-prostolauncher.ico'
	}
}

export const brand = _brand;

document.title = brand.name;

const link = document.createElement('link');
link.rel = 'icon';
link.href = brand.faviconPath;
link.type = brand.faviconPath.endsWith('.png') ? 'image/png' : 'image/x-icon';
document.head.appendChild(link);

document.body.className = brand.theme;
