import React from 'react';
import {Link} from 'react-router-dom';
import {brand} from '../Brand';
import './Logo.css';

const Logo = () => <Link to="/">
	<div className="logo">
		<img src={brand.logoPath} alt="Logo"/>
	</div>
</Link>;

export default Logo;
